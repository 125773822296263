import * as React from "react";
import HcpLayout from "../components/hcp/layout";
import { Seo } from "../components/seo";
import "./index.scss";
import whyWakixImg from "../images/home/hero-why-wakix.webp";
import whyWakixImgMobile from "../images/home/hero-why-wakix-mobile.webp";
import { TfiAngleRight } from "react-icons/tfi";
import { Link } from "gatsby";
import { Col, Row } from "react-grid-system";
import Slider from "../components/slider";
import HeroSlider from "../components/heroSlider";
import useDeviceSize from "../hooks/use-device-size";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazy-load";
// import copayIcon from "../images/icons/icon-copay-zero.svg";
import bannerBlock from "../images/home/cta-what-you-expect.webp";
import bannerBlockMobile from "../images/home/cta-what-you-expect-mobile.webp";
import SocialIconBlock from "../components/socialIconBlock";
import StickySupport from "../components/sticky-support";
import ViolatorCard from "../components/violator-card";

const IndexPage = () => {
  const deviceSizes = useDeviceSize();
  return (
    <HcpLayout pageClass="hcp-main hcp-homepage">
      <div className="hero-wrapper row-full">
        <div className="hero-content-wrapper">
          <div className="hero-banner">
            <ViolatorCard />
            <HeroSlider />
          </div>
          <StickySupport />

          <div className="hero-bottom-info">
            <div className="centered-content">
              <span className="font-25 asap ">
                FDA approved to treat excessive daytime sleepiness (EDS) or
                cataplexy in adults with narcolepsy and to treat EDS in children
                6 years of age and older with narcolepsy
              </span>
            </div>
          </div>
        </div>
      </div>

      <section className="first">
        <div className="centered-content text-center">
          <h2 className="green font-40">
            Could WAKIX Be the Right Treatment Option?
          </h2>
        </div>
      </section>

      <section className="why-wakix__wrapper">
        <div className="why-wakix__banner bg-cover">
          {deviceSizes?.smUp ? (
            <LazyLoad offset={300} debounce={false}>
              <img
                src={whyWakixImg}
                className="Why-waxix"
                alt="Chris, a real person with narcolepsy with cataplexy taking WAKIX, with friends outdoors"
              />
            </LazyLoad>
          ) : (
            <LazyLoad offset={300} debounce={false}>
              <img
                src={whyWakixImgMobile}
                className="why-wakix"
                alt="Chris, a real person with narcolepsy with cataplexy taking WAKIX, with friends outdoors"
              />
            </LazyLoad>
          )}
          <div className="centered-content">
            <div className="why-wakix__content">
              <div className="why-wakix__title">
                <h3 className="white font-30 darker-shadow">Why WAKIX?</h3>
              </div>
              <div className="btn-wrap">
                <Link
                  to="/about-wakix"
                  className="btn btn-primary btn-primary--md"
                >
                  Find out more
                  <TfiAngleRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pd-btm">
        <div className="centered-content text-center">
          <h2 className="green font-40">
            Hear <span className="lowercase">From Real People Taking</span>{" "}
            WAKIX
          </h2>
        </div>
      </section>

      <section className="gray-bg card-video__block">
        <div className="centered-content">
          <Row className="align-items-center flex-lg-row-reverse">
            <Col lg={7}>
              <div className="card-video__wrap">
                <Slider />
                {/* <CustomVideo videoId="253989945" videoThumbnail={videoThumbnail}/> */}
              </div>
            </Col>
            <Col lg={5}>
              <div className="card-video__content">
                <p>
                  Watch real people living with narcolepsy as they share their
                  journeys with the disorder and how WAKIX has helped.
                </p>
                <div className="btn-wrap">
                  <Link
                    to="/wakix-personal-stories"
                    className="btn btn-primary btn-primary--md"
                  >
                    See their stories
                    <TfiAngleRight />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section>
        <div className="centered-content text-center">
          <h2 className="green font-40">Starting Treatment With WAKIX?</h2>
        </div>
      </section>

      <section className="banner-block two-columns banner-block--alt no-pb">
        <div className="col bg-img">
          <div className="inner bg-cover align-center-desk-only">
            {deviceSizes?.smUp ? (
              <LazyLoad offset={300} debounce={false}>
                <img
                  src={bannerBlock}
                  alt="Kristen discussing WAKIX with her healthcare provider"
                />
              </LazyLoad>
            ) : (
              <LazyLoad offset={300} debounce={false}>
                <img
                  src={bannerBlockMobile}
                  alt="Kristen discussing WAKIX and symptoms with her healthcare provider"
                />
              </LazyLoad>
            )}
            <div className="banner-block__content ">
              <span className="asap">
                What to expect
                <br className="show-desktop" />
                <br className="show-mobile" /> with WAKIX
              </span>
            </div>
            <div className="btn-wrap mobile-spacer">
              <Link
                to="/starting-wakix/what-to-expect"
                className="btn btn-primary btn-primary--md"
              >
                Find out
                <TfiAngleRight />
              </Link>
            </div>
          </div>
        </div>
        <div className="col bg-green">
          <div
            className="inner cta-flt-left align-center-desk-only align-end"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="inner__content">
              <StaticImage
                src="../images/icons/icon-copay-zero.svg"
                placeholder="blurred"
                alt="$0 WAKIX copay program icon"
                className="zero-copay"
                width="128"
                height="114"
              />
              <div className="text">
                <h3 className="h3-spacer asap">
                  Copay program for eligible patients*
                </h3>
                <div className="btn-wrap">
                  <Link
                    to="/wakix-for-you-support-program"
                    className="btn btn-primary btn-primary--md"
                    aria-label="Learn more about the Wakix for You Support Program"
                    title="Learn more about the Wakix for You Support Program"
                  >
                    Learn more
                    <span className="visually-hidden">
                      about the Wakix for You Support Program
                    </span>
                    <TfiAngleRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="block-title">
              {/*  <p>You may be eligible to pay as little as a $0 copay on your WAKIX prescription with the <i className="italic">WAKIX for You</i> Program*</p> */}

              <p className="footnotes footnotes-symbols top-spacer no-spacer-mrg">
                *This offer is valid only for patients who have commercial
                (nongovernment-funded) insurance. Additional terms and
                conditions apply.
              </p>
              {/* <p className="footnotes footnotes-symbols second no-spacer">
                                Additional terms and conditions apply.
                            </p> */}
            </div>
          </div>
        </div>
      </section>

      <SocialIconBlock />
    </HcpLayout>
  );
};

export default IndexPage;

export const Head = () => (
  <Seo
    title="WAKIX® (pitolisant) | Official Site"
    description="WAKIX is FDA approved to treat excessive daytime sleepiness (EDS) or cataplexy in adults with narcolepsy and to treat EDS in children 6 years &amp; older with narcolepsy"
    keywords="narcolepsy treatment, narcolepsy medication, narcolepsy drugs, pitolisant, narcolepsy treatment drugs, treatment for narcolepsy, WAKIX, narcolepsy treatment medication, narcolepsy treatment options, sleep narcolepsy treatment, WAKIX FDA, WAKIX reviews"
  />
);
