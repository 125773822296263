import React from "react";
import { TfiAngleRight } from "react-icons/tfi";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
function Slide3() {
  return (
    <div
      className="hero-slide hero-slide3"
      alt="Tara, a real person with narcolepsy with cataplexy taking WAKIX, in an art studio"
    >
      <div className="hero-slide-content">
        <div className="centered-content">
          <div className="image-wrapper">
            <StaticImage
              className="home-hero-logo"
              src="../../images/home/home-hero-logo.webp"
              alt="Tara living with Narcolepsy"
              width={350}
              height={196}
              placeholder="none"
              style={{ transition: "opacity 0.5s" }}
              imgStyle={{
                width: "100%",
                height: "auto",
                opacity: "1",
              }}
              quality={95}
              layout="constrained"
            />
          </div>
          <div className="headline-text txt-shadow-white">
            For people living with <br className="show-desktop" />
            <span>NARCOLEPSY…</span>
          </div>
          <div className="wake-up-wrapper">
            <StaticImage
              src="../../images/WUTW-Italic-glow.webp"
              className="hero-wakeup-text with-glow"
              alt="Wake Up To WAKIX"
              layout="constrained"
              width={300}
              height={248}
              placeholder="none"
              style={{ transition: "opacity 0.5s" }}
              imgStyle={{ opacity: 1, width: "100%", height: "auto" }}
              quality={95}
            />
          </div>
          <div className="headline-cta">
            <div className="btn-container">
              <Link to="/about-wakix" className="btn btn-primary">
                Why WAKIX
                <TfiAngleRight />
              </Link>
            </div>
          </div>
          <div className="mobile-version">
            <div className="btn-container">
              <Link to="/about-wakix" className="btn btn-primary">
                Why WAKIX
                <TfiAngleRight />
              </Link>
            </div>
          </div>
          <div className="name-wrap">
            <StaticImage
              src="../../images/hero-slides/tara-name-title.webp"
              alt="Tara, a real person with narcolepsy with cataplexy taking WAKIX, in an art studio"
              width={213}
              height={102}
              quality={95}
              layout="fixed"
              placeholder="none"
              style={{ transition: "opacity 0.5s" }}
              imgStyle={{ opacity: 1 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slide3;
