import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import Slide1 from "./slides/Slide1";
import Slide2 from "./slides/Slide2";
import Slide3 from "./slides/Slide3";
import Slide4 from "./slides/Slide4";

function HeroSlider({ slidesArray, className='', ...props }) {
  const settings = {
    showArrows: false,
    showStatus: false,
    showThumbs: false,
    infiniteLoop: true,
    autoPlay: true,
    interval: 5000,
    preventMovementUntilSwipeScrollTolerance: true,
    swipeScrollTolerance: 50,
  };
  return (
    <div {...props} className={`hero-slider ${className}`}>
      <Carousel {...settings}>
        <Slide1 />
        <Slide2 />
        <Slide3 />
        <Slide4 />
      </Carousel>
    </div>
  );
}

export default HeroSlider;
