import React from "react";
import { TfiAngleRight } from "react-icons/tfi";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
function Slide4() {
  return (
    <div 
      className="hero-slide hero-slide4"
      alt="Kristen, a real person with narcolepsy taking WAKIX, in a kitchen preparing food"
    >
      <div className="hero-slide-content">
        <div className="centered-content">
          <div className="image-wrapper">
            <StaticImage
              className="home-hero-logo"
              src="../../images/home/home-hero-logo.webp"
              alt="Kristen living with Narcolepsy"
              width={350}
              height={196}
              placeholder="none"
              style={{ transition: 'opacity 0.5s' }}

              imgStyle={{
                width: '100%',
                height: 'auto',
                opacity: '1',
              }}

              quality={95}
              layout="constrained"
            />
          </div>
          <div className="headline-text txt-shadow-white">
            For people living with <br className="show-desktop" />
            <span>NARCOLEPSY…</span>
          </div>
          <div className="wake-up-wrapper">
            <StaticImage
              src="../../images/WUTW-Italic-glow.webp"
              className="hero-wakeup-text with-glow pos-left"
              alt="Wake Up To WAKIX"
              layout="constrained"
              width={300}
              height={248}
              placeholder="none"
              style={{ transition: 'opacity 0.5s' }}
              imgStyle={{
                opacity: 1, width: '100%',
                height: 'auto',
              }}
              quality={95}
            />
          </div>
          <div className="headline-cta">
            <div className="btn-container">
              <Link to="/about-wakix" className="btn btn-primary">
                Why WAKIX
                <TfiAngleRight />
              </Link>
            </div>
          </div>
          <div className="mobile-version">
            <div className="btn-container">
              <Link to="/about-wakix" className="btn btn-primary">
                Why WAKIX
                <TfiAngleRight />
              </Link>
            </div>
          </div>
          <div className="name-wrap on-left">
            <StaticImage
              src="../../images/hero-slides/kristen-name-title-green.webp"
              alt="Kristen, a real person with narcolepsy taking WAKIX, in a kitchen preparing food"
              width={200}
              height={100}
              quality={95}
              layout="constrained"
              placeholder="none"
              style={{ transition: 'opacity 0.5s' }}
              imgStyle={{ opacity: 1 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slide4;
